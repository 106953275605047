
.resumeDocument {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;

    .edit-title {
      width: 600px;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .versionsSelect {
      margin-left: 18px;
      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }
      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }
      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }
    .typeSelect {
      margin-left: 0px;
      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }
      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\7c7b\578b';
        color: #4e5969;
      }
      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 156px !important;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }
  }

  .Search-input {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    text-align: left;

    .arco-input.Search {
      width: 630px;
      height: 45px;
      background: #ffffff;
      font-size: 14px;
      border-radius: 10px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .arco-input::-webkit-input-placeholder {
      color: #8d93a1;
      font-size: 14px;
    }

    .Header-init-btn.arco-btn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      margin-left: 18px;
    }
  }

  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent !important;
    border-radius: 22px;
    padding: 0px;
    padding-top: 28px;
    padding-bottom: 30px !important;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    text-align: left;
    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      padding-left: 3rem;
      padding-right: 23px;
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .more-loding {}
  }

  #layout_id .arco-layout-content{
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 14px;
  }
  .upload-file {
    width: 409px;
    height: 100%;
    border-radius: 14px;
    background: #FFFFFF;
    margin-bottom: 10px;
    color: #0A1629;
    text-align: left;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 17px 11px 17px 27px;
    .arco-upload{
      display: flex;
      align-items: center;
      width: 150px;
      height: 150px;
      margin-top: 30px;
      border-radius: 12px;
      border: 1px dashed #ccc;
      &.arco-upload-hide{
        display: none;
      }
    }
    .arco-upload-list{
      margin-top: 30px;
    }
  }
  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      margin-top: 17px;
      overflow: auto;

      .Continuation-list {
        width: 100%;
        height: auto;
        text-align: left;

        .Continuation-item {
          display: inline-block;
          width: 14.2%;
          height: 20.4rem;
          // width: 170px;
          // height: 240px;
          height: 100%;
          background: transparent;
          border-radius: 6px;
          padding: 0 30px;
          margin-bottom: 65px;
          cursor: pointer;

          .arco-avatar {
            width: 100%;
            height: 100%;
          }

          .arco-image-img {
            width: 100%;
            height: 100%;
          }

          .Continuation-img {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            border: 1px solid #d8e0f0;
            box-shadow: 0px 2px 20px 0px rgba(233, 238, 247, 0.50);
            overflow: hidden;

            &.file-docx::after {
              position: absolute;
              content: '';
              bottom: 1rem;
              right: 1rem;
              width: 1.6rem;
              height: 2rem;
              background: #ffffff;
              border-radius: 2px;
              border: 0.5rem solid #ffffff;
              background-image: url("/asserts/icon_word_normal.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            &.file-pdf::after {
              position: absolute;
              content: '';
              bottom: 1rem;
              right: 1rem;
              width: 1.6rem;
              height: 2rem;
              background: #ffffff;
              border-radius: 2px;
              border: 0.5rem solid #ffffff;
              background-image: url("/asserts/icon_pdf_normal.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }

          .Continuation-img:hover:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: rgba(0, 0, 0, 0.10);
            border-radius: 6px;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
            z-index: 2;
          }

        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 16.6rem;
      height: 4.8rem;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 1.4rem;
      margin-top: 2rem;
      border-radius: 1rem;
    }

    .Continuation-loding {
      margin-top: 22.8rem;
    }

    .Spin-loding {
      margin-bottom: 2rem;
    }
  }

  .Continuation-Empty {
    margin-top: 22.8rem;
  }
}
.arco-modal.custom-modal-download-document {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);

  .arco-modal-footer {
    text-align: center;
  }

  .arco-btn.download-btn {
    height: 44px;
    border-radius: 10px;
  }

  .custom-modal-download-content {
    position: relative;
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 24px 0px;
    padding-bottom: 0;
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      border: 1px solid #e4eaf4;
    }
  }

  .arco-modal-close-icon {
    top: 0px;
    right: -46px;
    width: 34px;
    height: 34px;
    background: #f5f8ff;
    font-size: 18px;
    color: #0a1629;
    border-radius: 10px;
    line-height: 36px;
    text-align: center;
  }

  .arco-icon-hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .Tags {
    width: 100%;
    height: 100px;
    text-align: left;
    overflow: hidden;

    span {
      display: inline-block;
      padding: 0px 13px;
      height: 31px;
      line-height: 31px;
      border-radius: 6px;
      margin-right: 12px;
      margin-top: 15px;
      color: #656565;
      background-color: #f5f5f5;

      &.hl-k {
        color: #406EFF;
        background-color: #ebf3ff;
      }

      &.hl-m {
        color: #895600;
        background-color: #fff8d9;
      }
    }
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}

.resumeDocument .semanticprototype-Layout .arco-layout-sider-light.Sider-left{
  background: #fff;
    border-radius: 14px;
    box-shadow: 0 6px 58px 0 rgba(196,203,214,.1);
    height: 100%;
    margin-right: 20px;
    width: 409px!important;
    .arco-layout-sider-children{
      color: var(--color-white);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-stretch: condensed;
    justify-content: center;
    text-align: center;
    }
    .Sider-left-configure{
      height: 100%;
      padding: 0 10px 10px;
      position: relative;
      text-align: left;
      width: 100%;
    }
    .Continuation-input{
      width: 100%;
      height: 100%;
      .arco-textarea{
        background: #fff;
        border-radius: 14px;
        color: #0b172a;
        height: 100%;
        padding: 12px;
        resize: none;
        width: 100%;
        height: 100%;
      }
    }
}

.Paragraph-generate-text{
  text-align: center;
  padding: 20px 25px 17px;
  .Continuation-result{
    margin-top: 30px;
    text-align: right;
    .Continuation-list{
      overflow: auto;
      text-align: left;
      width: 100%;
      .Continuation-item{
        background: #fbfbfb !important; 
        border: 1px solid #fbfbfb;
        border-radius: 8px !important;
        color: #0b172a;
        cursor: pointer;
        margin-bottom: 28px !important;
        padding: 16px !important;
        width: 100% !important;
      }
    }
  }
}


.Silder-resume{
  background-color: transparent !important;
  .Continuation-input{
    background-color: #ffffff;
    padding: 17px 15px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 14px;
  }
  .cowrite{
    width: 100%;
    margin: 0 auto 10px;
    background-color: #ffffff;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 14px;
    padding: 17px 15px 35px;
    
    .cowrite-title{
      color: #0a1629;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .arco-btn-size-default{
      padding: 0;
    }
    .arco-select-view{
      height: 45px !important;
      line-height: 45px !important;
      background: #ffffff;
      border-radius: 14px;
      border: 1px solid rgba(196, 203, 214, 0.5);
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
      line-height: 45px;
      text-align: center;
    }
  }
}