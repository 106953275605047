.payment-details {
    .over-header{
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .over-header-left{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .arco-dropdown-popup-visible .arco-icon-down{
            transform: rotate(180deg);
          }
          .custom-select{
            margin-left: 10px;
            border: 1px solid #CDD3DF;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
          }
          .custom-select:hover{
            border: 1px solid #406EFF;
          }
          #infoselect:hover .custom-type{
            color: #406EFF;
          }
          .custom-type{
            color: #4E5969;
          }
          .custom-shile{
            margin: 0 10px;
            width: 2px;
            height: 15px;
            background-color: #CDD3DF;
          }
          .arco-dropdown-menu-item{
            padding-left: 10px;
          }
        }
        .DatePicker-RangePicker {
            position: relative;
            .RangePicker-box {
              border-radius: 14px;
              background-color: #FFFFFF;;
              box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
              border: 1px solid #CDD3DF;
              height: 43px;
              &:hover{
                border: 1px solid #406EFF;
              }
            }
          }
          .custom-White-button{
            height: 38px;
            border-radius: 12px;
            margin-left: 10px;
          }
    }

}
.arco-radio-group .arco-radio.time-radio{
  border-radius: 12px;
  border: 1px solid #D8E0F0;
  padding: 8px 15px;
  padding-left: 15px !important;
  margin-bottom: 0;
  &:hover{
    border: 1px solid #406EFF;
  }
  .arco-radio-text{
    margin-left: 10px !important;
  }
 
}
.down-modal  {
  .arco-modal-footer button{
    border-radius: 12px;
  }
  .RangePicker-box {
    border-radius: 14px;
    background-color: #FFFFFF;;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
    border: 1px solid #CDD3DF;
    height: 43px;
    &:hover{
      background-color: #fff;
      border: 1px solid #406EFF;
    }
  }
}