.product-experience {
  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .edit-title {
      width: 300px !important;
      .arco-typography-operation-edit {
        opacity: 0;
      }

      .Header-uptitle:hover {
        .arco-typography-operation-edit {
          opacity: 1;
        }
      }
    }

    .arco-btn {
      margin-left: 18px;
    }

    .arco-btn-shape-circle {
      width: 100px;
      height: 45px;
      color: #0b172a;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .versionsSelect {
      margin-left: 18px;

      .arco-select-view {
        position: relative;
        padding-left: 60px;

        .arco-select-view-value {
          text-align: left;
        }
      }

      .arco-select-view::before {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        content: '\6a21\578b';
        color: #4e5969;
      }

      .arco-select-view::after {
        position: absolute;
        height: 16px;
        left: 47px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        border-left: 1px solid #cdd3df;
      }
    }

    .arco-select-size-default.arco-select-single .arco-select-view {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      line-height: 45px;
      text-align: center;
    }

    .primary_model_name {
      display: inline-block;
      width: 220px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;

      .primary_model_name_title {
        color: #4e5969;

        .arco-divider {
          border-color: #cdd3df;
          margin-left: 8px;
          margin-right: 13px;
        }
      }
    }

    .primary_model_name.primary_model_name_aigc {
      display: inline-block;
      width: 340px;
      height: 45px;
      line-height: 45px;
      background: #e7ecf7;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      font-size: 14px;
      padding: 0 16px;
      position: relative;
      .arco-typography {
        width: 228px;
        color: #3f8cff;
      }
      .primary_model_name_title {
        color: #0B172A;

        .arco-divider {
          border-color: #4e5969;
          margin-left: 8px;
          margin-right: 13px;
          border-width: 2px;
          height: 1.4em;
          margin-bottom: 3px;
        }
      }
      .arco-typography-operation-copy{
        position: absolute;
        top: calc(50% - 14px);
        right: 18px;
        font-size: 18px;
      }
    }
    .primary_model_name.primary_model_token_aigc{
      width: 290px;
      margin-right: 15px;
      .arco-typography {
        width: 188px;
      }
    }
    .setting_primary_model_name{
      display: inline-block;
      width: 110px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: #fff;
      border-radius: 14px;
      margin-left: 10px;
      // margin-right: 15px;
      font-size: 14px;
      cursor: pointer;
    }

    .primarybtn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      margin-left: 18px;
    }

  }

  .semanticprototype-Layout {
    width: 100%;

    .arco-layout-sider-light.Sider-left {
      width: 409px !important;
      height: 100%;
      background: #ffffff;
      border-radius: 14px;
      margin-right: 20px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    }

    .Sider-left-w {
      width: 100%;
    }

    .Sider-left-configure {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 10px;

      .Continuation-input {
        width: 100%;
        height: 100%;

        .arco-textarea-wrapper {
          width: 100%;
          height: 100%;
        }

        .arco-textarea {
          height: 100%;
          resize: none;
          background: #ffffff;
          border-radius: 14px;
          color: #0b172a;
          padding: 12px;
        }

        .arco-textarea-word-limit {
          background: transparent;
        }
      }
    }

    .generation-bottom {
      width: 100%;
      text-align: right;
      margin-top: 13px;

      .Start-generation {
        width: 133px;
        height: 48px;
        background: #406eff;
        border-radius: 14px;
        box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
      }
    }
  }
  .sass-semanticprototype-Layout{
    border: 2px solid #dfe1e7;
    border-radius: 12px;
    min-height: 650px;
  }
  .iframeFullScreen{
    width: 100vw !important;
    height: 100vh !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .sass-setting{
    width: 0;
    padding: 0;
    background-color: #fff;
    margin-left: 0;
    border-radius: 24px;
    box-shadow: 0 0 6px #ccc;
    overflow: auto;
    transition: all 0.2s ease-in-out;
    .sass-setting-title{
      font-size: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 4px;
      border-bottom: 2px solid #ccc;
    }
    .sass-setting-brand-title{
      font-size: 18px;
      font-weight: 500;
    }
    .sass-setting-subtitle{
      color: #1D2129;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 10px;
    }
    .sass-setting-brand{
      // margin-top: 10px;
      .sass-setting-box{
        display: flex;
        align-items: flex-start;
        flex-direction: column;

      }
      .logo-radio{
        margin-bottom: 10px;
        .arco-radio{
          width: fit-content;
          min-width: 95px;
          margin-bottom: 0;
          line-height: 18px;
          padding: 10px 18px;
          padding-left: 10px !important;
          border: 1px solid #D8E0F0;
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.2221);
          transition: all 0.2s ease-in-out;
          .arco-radio-text{
            margin-left: 7px !important;
          }
          &:hover{
            border: 1px solid #406EFF;
            background: #EBF2FF;
          }
        }
      }
      .sass-setting-brand-logo{
        margin-bottom: 20px;
        .arco-upload-list{
          position: relative;
          .arco-card-cover{
            width: 110px;
            height: 110px;
          }
          .arco-card{
            border-radius: 14px;
          }
          .arco-card-body{
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .trigger{
          justify-content: flex-start;
          .uploadlogo{
            width: 110px;
            height: 110px;
            position: relative;
            text-align: center;
            padding-top: 34px;
            background-color: #E8ECF7;;
            border-radius: 12px;
            span{
              font-size: 14px;
              color: #3D3D3D;
            }
          }
          .upload-bottom{
            width: 100%;
            height: 26px;
            background-color: rgba(0,0,0,0.6);
            border-radius: 0 0 12px 12px;
            // text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            img{
              width: 18px;
              height: 18px;
              margin-top: 3px;
            }
            &:hover{
              background-color: rgba(0,0,0,0.5);
            }
          }
          .icon-icon_chuangjian_24{
            font-size: 24px;
          }
        }
      }
      .sass-setting-brand-color{
        margin-bottom: 20px;
        .arco-radio{
          margin-bottom: 10px;
        }
        .arco-radio-text{
          margin-left: 10px !important;
          .tips{
            padding-left: 10px;
          }
        }

      }
      .sass-setting-input{
        // width: 110px;
        height: 42px;
        margin-left: 10px;
        background-color: #ffffff;
        border-radius: 14px;
        border: 1px solid #D8E0F0;
        &.arco-input-inner-wrapper-focus{
          border: 1px solid #406EFF;
        }
      }
      .sass-setting-brand-reporter{
        margin-bottom: 20px;
      }
    }
  }
  .sass-setting-show{
    width: 480px;
    padding: 16px 22px;
    margin-left: 20px;
  }
  .setting-custom-button{
    color: #0c0c0f;
  }
.setting-custom-button.arco-btn-size-default > svg + span{
    margin-left: 2px;
}
  .iframe-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }
  .experience-result {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 14px;
    padding: 17px 25px;
    padding-top: 24px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
    font-size: 14px;
    text-align: left;


    .arco-result-icon-tip {
      width: 90px;
      height: 90px;
      font-size: 30px;
      line-height: 95px;
    }

    .result_title {
      font-size: 16px;

      .arco-typography {
        margin-top: 0;
        margin-bottom: 0;
      }

      .result-remarks {
        color: #8d93a1;
        font-size: 12px;
      }
    }

    .arco-result .arco-result-title {
      margin-bottom: 10px;
    }

    .Empty-error.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }

    .Empty-none.arco-empty .arco-empty-wrapper img {
      height: 113px;
    }
  }

  .Paragraph-generate {
    text-align: center;

    .Continuation-result {
      text-align: right;
      margin-top: 30px;

      .Continuation-list {
        width: 100%;
        text-align: left;
        overflow: auto;

        .Continuation-item {
          width: 100%;
          background: #fbfbfb;
          border-radius: 8px;
          margin-bottom: 28px;
          padding: 16px;
          color: #0B172A;
          border: 1px solid #fbfbfb;
          cursor: pointer;

          .Preamble {
            color: #406EFF;
          }

          &:hover {
            background: #f2f7ff;
            border: 1px solid #406eff;
          }
        }

        .Continuation-item:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .arco-btn.IconSync-btn {
      width: 166px;
      height: 48px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.10);
      margin-top: 33px;
      border-radius: 10px;
    }

    .Continuation-loding {
      margin-top: 232px;
    }

    .Spin-loding {
      margin-bottom: 20px;
    }
  }

  .Continuation-Empty {
    margin-top: 219px;
  }

  //嵌入面板样式
  .iframe-box{
    border-radius: 22px;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.50);
  }
}

.arco-select-popup {
  color: #0b172a;
  background: #ffffff;
  border-radius: 14px !important;
  border: 0 !important;
  padding: 4px 10px !important;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.30) !important;
}

.arco-select-popup .arco-select-option-hover {
  background-color: #eff4f8 !important;
}

.arco-select-popup .arco-select-option {
  border-radius: 10px !important;
}

.myapp-fullscreen{
  position: inherit;
}
.payment-modal.arco-modal.setting-model{
  width: 900px;
  .setting-radiro{
    font-size: 18px;
    .arco-radio-group{
      margin-left: 20px
    }
    .arco-radio{
      margin-bottom: 0;
      font-size: 18px;
      .arco-radio-text{
        padding-left: 5px;
      }
    }
  }
  .setting-table{
    .settingadd{
      border-radius: 6px;
    }
    .settingdelete{
      border-color: red;
      color: red;
      border-radius: 6px;
    }
  }
}

.aigcSetting{
  min-width: 260px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  .setting-item{
    margin: 0 0 20px;
    font-size: 15px;
  }
  .primary_model_name_fix{
    height: 40px;
  }
  .primary_model_name{
    margin-top: 10px;
    background: #e7ecf7;
    height: 40px;
    // line-height: 40px;
    border-radius: 12px;
    padding: 0 15px 0 0;
    .arco-typography{
      flex: 1;
    }
    .arco-typography-edit-content{
      margin-bottom: 0;
    }
  }
}